import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_MEETING, GET_MEETINGS, GET_MEETING_INSTANCE, CLEAR_MEETINGS } from 'actions/meetings';
var INITIAL_STATE = {
  meetings: [],
  meeting: null,
  meetingInstance: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_MEETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        meeting: action.payload
      });
    case GET_MEETINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetings: action.payload
      });
    case GET_MEETING_INSTANCE:
      if (action.payload) {
        return _objectSpread(_objectSpread({}, state), {}, {
          meetingInstance: action.payload
        });
      }
    case CLEAR_MEETINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        meetings: []
      });
    default:
      return state;
  }
}