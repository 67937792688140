import * as msal from "@azure/msal-browser";
import sniffer from 'sniffer';
var configSettings = require('../../app-config/config');
var config = configSettings.GET_CONFIG();
var storeAuthStateInCookie = function storeAuthStateInCookie() {
  if (sniffer.isFirefox) {
    return true;
  }
  return false;
};
var msalConfig = {
  auth: config.msal,
  cache: {
    // TODO: Decide if we should use sessionStorage (default) or localStorage here
    // localStorage seems to work better for our use case
    cacheLocation: "localStorage",
    storeAuthStateInCookie: storeAuthStateInCookie() // Set this to "true" if you are having issues on IE11 or Edge
  }
};
var msalInstance = new msal.PublicClientApplication(msalConfig);

// Export MSAL instance
export default msalInstance;