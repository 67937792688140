import { getRootUrl } from 'helpers/url';
export var backButtonEnable = function backButtonEnable() {
  var current = window.location.pathname;
  if (current[current.length - 1] == '/') {
    // Remove trailing slash
    current = current.slice(0, -1);
  }

  // Remove trailing slash on getRootUrl since it always contains one
  if (current == getRootUrl().slice(0, -1)) {
    return false;
  } else {
    if (current == '/') {
      return false;
    }
    if (current == '/assignments') {
      return false;
    }
    if (current == '/menu') {
      return false;
    }
    if (current == '/posts') {
      return false;
    }
    if (current == '/plans') {
      return false;
    }
    if (current == '/groups') {
      return false;
    }
    if (current == '/schedule') {
      return false;
    }
    if (current == '/notifications') {
      return false;
    }
    if (current == '/conversations') {
      return false;
    }
    if (current == '/absence') {
      return false;
    }
    if (current == '/report/attendance') {
      return false;
    }
    if (current == '/courses') {
      return false;
    }
    if (current == '/progress') {
      return false;
    }
    if (current == '/conversations/admin/flagged') {
      return false;
    }
    if (current == '/forms/all') {
      return false;
    }
    if (current == '/meetings') {
      return false;
    }
    return true;
  }
};