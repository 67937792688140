import Moment from 'moment';
import api from '../lib/api';
export var ADD_ERROR = 'ADD_ERROR';
export var GET_STATUS = 'GET_STATUS';
export var GET_SECTION_COURSES = 'GET_SECTION_COURSES';
export var GET_GOALS = 'GET_GOALS';
export function addError(message, type) {
  var errorType = type || 'warning';
  var error = {
    msg: message,
    type: errorType,
    target: 'API',
    time: Moment()
  };
  return {
    type: ADD_ERROR,
    payload: error
  };
}
export var getStatus = function getStatus(lcid) {
  return {
    type: GET_STATUS,
    payload: new Promise(function (resolve, reject) {
      if (lcid == "sv") {
        lcid = 'sv-SE';
      }
      if (lcid == "en") {
        lcid = 'en-US';
      }
      if (lcid == "nb") {
        lcid = 'nb-NO';
      }
      if (lcid == "fi") {
        lcid = "fi-FI";
      }
      if (lcid == "da") {
        lcid = "da-dk";
      }
      api.get("base/status/".concat(lcid)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGoals = function getGoals() {
  return {
    type: GET_GOALS,
    payload: new Promise(function (resolve) {
      api.get("base/goals").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSectionCourses = function getSectionCourses(id) {
  return {
    type: GET_SECTION_COURSES,
    payload: new Promise(function (resolve) {
      api.get("courses/GetBySection/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};