import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENTS, GET_GUARDIAN_STUDENT_SCHEDULE, CLEAR_GUARDIAN_STUDENT_SCHEDULE } from '../actions/guardians';
var INITIAL_STATE = {
  students: null,
  activeStudent: null,
  currentStudent: null,
  schedule: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var currentStudent = state.currentStudent;
  switch (action.type) {
    case GET_GUARDIAN_STUDENTS:
      if (state.activeStudent != null) {
        currentStudent = action.payload.find(function (student) {
          return student.id == state.activeStudent;
        });
      }
      if (currentStudent == null) {
        currentStudent = state.currentStudent;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        students: action.payload,
        currentStudent: currentStudent
      });
    case GET_GUARDIAN_STUDENT_SCHEDULE:
      return _objectSpread(_objectSpread({}, state), {}, {
        schedule: action.payload
      });
    case CLEAR_GUARDIAN_STUDENT_SCHEDULE:
      return _objectSpread(_objectSpread({}, state), {}, {
        schedule: []
      });
    default:
      return state;
  }
}