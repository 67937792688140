import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_ASSESSMENT, GET_ASSESSMENTS, GET_SECTIONS_FROM_ASSESSMENTS, CLEAR_ASSESSMENTS, GET_STUDENT_COURSES_GOALS, GET_STUDENT_ASSESSMENT_STATISTICS } from "actions/assessments";
var INITIAL_STATE = {
  assessments: [],
  assessment: null,
  studentStatistics: null,
  studentOverview: null,
  progressSections: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_ASSESSMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessment: action.payload
      });
    case GET_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });
    case GET_STUDENT_COURSES_GOALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentOverview: action.payload
      });
    case GET_STUDENT_ASSESSMENT_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentStatistics: action.payload
      });
    case GET_SECTIONS_FROM_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        progressSections: action.payload
      });
    case CLEAR_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessment: null,
        assessments: [],
        studentStatistics: null,
        studentOverview: null,
        progressSections: null
      });
    default:
      return state;
  }
}