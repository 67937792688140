import api from 'lib/api';
import authentication from 'lib/authentication';
export var GET_GUARDIAN_STUDENTS = 'GET_GUARDIAN_STUDENTS';
export var GET_GUARDIAN_STUDENT_SCHEDULE = 'GET_GUARDIAN_STUDENT_SCHEDULE';
export var CLEAR_GUARDIAN_STUDENT_SCHEDULE = 'CLEAR_GUARDIAN_STUDENT_SCHEDULE';
export var getGuardianStudents = function getGuardianStudents() {
  return {
    type: GET_GUARDIAN_STUDENTS,
    payload: new Promise(function (resolve, reject) {
      api.get('Guardians/me/studentList').then(function (response) {
        resolve(response.data);
      })["catch"](function (reason) {
        if (reason != null && reason.response != null) {
          if (reason.response.status == 401) {
            authentication.logout();
          }
        }
      });
    })
  };
};
export var getGuardianStudentSchedule = function getGuardianStudentSchedule(studentId, starttime, endtime) {
  return {
    type: GET_GUARDIAN_STUDENT_SCHEDULE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/schedule/").concat(starttime, "/").concat(endtime)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearGuardianStudentSchedule = function clearGuardianStudentSchedule() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_SCHEDULE,
    payload: []
  };
};