import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
export var getConversationParticipants = function getConversationParticipants(conversation, user) {
  if (conversation == null) {
    return null;
  }
  var users = [];
  conversation.memberships.forEach(function (membership) {
    if (user != null && user.id == membership.membershipID) {
      return true;
    }
    if (membership.membershipType.indexOf('SECTION') > -1) {
      users.push(_objectSpread({
        id: membership.membershipID,
        membershipType: membership.membershipType
      }, membership.section));
    } else {
      users.push(_objectSpread({
        id: membership.membershipID,
        membershipType: membership.membershipType
      }, membership.user));
    }
  });
  return users;
};