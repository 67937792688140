import api from '../lib/api';
export var GET_GUARDIAN_STUDENT_COURSES = 'GET_GUARDIAN_STUDENT_COURSES';
export var GET_GUARDIAN_STUDENT_LATEST_VERDICTS = 'GET_GUARDIAN_STUDENT_LATEST_VERDICTS';
export var GET_GUARDIAN_STUDENT_VERDICT_SCALE = 'GET_GUARDIAN_STUDENT_VERDICT_SCALE';
export var GET_GUARDIAN_STUDENT_VERDICTS = 'GET_GUARDIAN_STUDENT_VERDICTS';
export var CLEAR_GUARDIAN_STUDENT_STUDYPLAN = 'CLEAR_GUARDIAN_STUDENT_STUDYPLAN';
export var GET_ASSIGNMENT_ASSESSMENTS = 'GET_ASSIGNMENT_ASSESSMENTS';
export var GET_PLAN_ASSESSMENTS = 'GET_PLAN_ASSESSMENTS';
export var getGuardianStudentLatestVerdicts = function getGuardianStudentLatestVerdicts(studentId) {
  return {
    type: GET_GUARDIAN_STUDENT_LATEST_VERDICTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/verdicts")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentVerdicts = function getGuardianStudentVerdicts(studentId, courseId) {
  return {
    type: GET_GUARDIAN_STUDENT_VERDICTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/verdicts?courseId=").concat(courseId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getVerdictScales = function getVerdictScales(studentId) {
  return {
    type: GET_GUARDIAN_STUDENT_VERDICT_SCALE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/verdictScales")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearStudyPlans = function clearStudyPlans() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_STUDYPLAN,
    payload: null
  };
};
export var getPlanAssessments = function getPlanAssessments(studentId, subjectCode) {
  return {
    type: GET_PLAN_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("students/".concat(studentId, "/assessments/").concat(subjectCode, "/assignments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getAssignmentAssessments = function getAssignmentAssessments(studentId, subjectCode) {
  return {
    type: GET_ASSIGNMENT_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("students/".concat(studentId, "/assessments/").concat(subjectCode, "/plans")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};