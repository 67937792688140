import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENT_PLANS, GET_GUARDIAN_STUDENT_PLAN, GET_GUARDIAN_STUDENT_PLAN_ASSESSMENTS, CLEAR_GUARDIAN_STUDENT_PLANS } from '../actions/planning';
var INITIAL_STATE = {
  plans: null,
  plan: null,
  assessments: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_GUARDIAN_STUDENT_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.payload
      });
    case CLEAR_GUARDIAN_STUDENT_PLANS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: null,
        plan: null,
        assessments: null
      });
    case GET_GUARDIAN_STUDENT_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        plan: action.payload
      });
    case GET_GUARDIAN_STUDENT_PLAN_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });
    default:
      return state;
  }
}