import api from 'lib/api';
export var GET_GUARDIAN_STUDENT_POSTS = 'GET_GUARDIAN_STUDENT_POSTS';
export var GET_GUARDIAN_SECTION_POSTS = 'POSTS_GET_GUARDIAN_SECTION_POSTS';
export var CLEAR_GUARDIAN_STUDENT_POSTS = 'CLEAR_GUARDIAN_STUDENT_POSTS';
export var GET_GUARDIAN_STUDENT_POST = 'GET_GUARDIAN_STUDENT_POST';
export var getGuardianStudentPosts = function getGuardianStudentPosts(studentId, page, size) {
  return {
    type: GET_GUARDIAN_STUDENT_POSTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/posts/page/").concat(page, "/size/").concat(size)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSectionPosts = function getSectionPosts(studentId, sectionId, page, size) {
  return {
    type: GET_GUARDIAN_SECTION_POSTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/posts/page/").concat(page, "/size/").concat(size, "?sectionId=").concat(sectionId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentPost = function getGuardianStudentPost(studentId, postId) {
  return {
    type: GET_GUARDIAN_STUDENT_POST,
    payload: new Promise(function (resolve, reject) {
      api.get("Guardians/me/students/".concat(studentId, "/posts/").concat(postId)).then(function (response) {
        if (response.status > 201) {
          reject(response);
          return true;
        }
        resolve(response.data);
      });
    })
  };
};
export var clearGuardianStudentPosts = function clearGuardianStudentPosts() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_POSTS,
    payload: true
  };
};