import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { FETCH_PRESCHOOL_GOALS, GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS, RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS, GET_PRESCHOOL_GOALS_USER_STATISTICS } from 'actions/preschoolform';
var initialState = {
  preschoolGoals: [],
  linkedData: [],
  userStatistics: null
};
export default function Preschoolgoal(state, action) {
  var type = action.type,
    value = action.value;
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (type) {
    case FETCH_PRESCHOOL_GOALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        preschoolGoals: action.payload
      });
      break;
    case GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
      if (action.payload != null) {
        return _objectSpread(_objectSpread({}, state), {}, {
          linkedData: [].concat(_toConsumableArray(state.linkedData), [action.payload])
        });
      }
      return state;
    case RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        linkedData: []
      });
    case GET_PRESCHOOL_GOALS_USER_STATISTICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userStatistics: action.payload
      });
    default:
      return state;
      break;
  }
}