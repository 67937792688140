import api from '../lib/api';
export var GET_TRANSLATIONS_FOR_LANGUAGE = 'LANGUAGES_GET_TRANSLATIONS_FOR_LANGUAGE';
export var getTranslations = function getTranslations(lcid) {
  return {
    type: GET_TRANSLATIONS_FOR_LANGUAGE,
    payload: new Promise(function (resolve) {
      if (lcid == "sv") {
        lcid = 'sv-SE';
      }
      if (lcid == "en") {
        lcid = 'en-US';
      }
      if (lcid == "nb") {
        lcid = 'nb-NO';
      }
      if (lcid == "da") {
        lcid = "da-DK";
      }
      if (lcid == "uk") {
        lcid = "uk";
      }
      api.get("translations/".concat(lcid, "?service=HALDOR_GUARDIANS"), true).then(function (response) {
        resolve(response.data);
      });
    })
  };
};