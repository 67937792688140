import Moment from 'moment';
export var getUserAssessmentDates = function getUserAssessmentDates() {
  var startDateLocalStorage = localStorage.getItem('assessment-start-date');
  var endDateLocalStorage = localStorage.getItem('assessment-end-date');
  var dates = {
    start: Moment().subtract(6, 'months').format('YYYY-MM-DD'),
    end: Moment().format('YYYY-MM-DD')
  };
  if (startDateLocalStorage != null && startDateLocalStorage != "Invalid date") {
    dates.start = Moment(startDateLocalStorage).format('YYYY-MM-DD');
  }
  if (endDateLocalStorage != null && endDateLocalStorage != "Invalid date") {
    dates.end = Moment(endDateLocalStorage).format('YYYY-MM-DD');
  }
  return dates;
};