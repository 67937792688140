import api from 'lib/api';
export var GET_MEETING_TIME_BLOCKS = 'GET_MEETING_TIME_BLOCKS';
export var getMeetingTimeBlocks = function getMeetingTimeBlocks(meetingId) {
  return {
    type: GET_MEETING_TIME_BLOCKS,
    payload: new Promise(function (resolve) {
      api.get("timeblocks/meeting/".concat(meetingId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};