import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENT_DOCUMENTATIONS, GET_GUARDIAN_STUDENT_DOCUMENTATION, GET_GUARDIAN_STUDENT_DOCUMENTATION_FILE, CLEAR_GUARDIAN_STUDENT_DOCUMENTATION, GET_GUARDIAN_SECTION_DOCUMENTATIONS } from 'actions/documents';
var INITIAL_STATE = {
  documentations: [],
  sectionDocumentations: [],
  documentation: null,
  file: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_GUARDIAN_STUDENT_DOCUMENTATIONS:
      var combined = state.documentations;
      action.payload.forEach(function (d) {
        var checkExisting = combined.find(function (cb) {
          return cb.id == d.id;
        });
        if (checkExisting == null) {
          combined.push(d);
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        documentations: combined
      });
    case GET_GUARDIAN_SECTION_DOCUMENTATIONS:
      var sectionCombined = state.sectionDocumentations;
      action.payload.forEach(function (d) {
        var checkExisting = sectionCombined.find(function (cb) {
          return cb.id == d.id;
        });
        if (checkExisting == null) {
          sectionCombined.push(d);
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        sectionDocumentations: sectionCombined
      });
    case GET_GUARDIAN_STUDENT_DOCUMENTATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        documentation: action.payload
      });
    case GET_GUARDIAN_STUDENT_DOCUMENTATION_FILE:
      return _objectSpread(_objectSpread({}, state), {}, {
        file: action.payload
      });
    case CLEAR_GUARDIAN_STUDENT_DOCUMENTATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        documentations: [],
        sectionDocumentations: []
      });
    default:
      return state;
  }
}