import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENT_COURSES, GET_GUARDIAN_STUDENT_VERDICTS, CLEAR_GUARDIAN_STUDENT_STUDYPLAN, GET_GUARDIAN_STUDENT_LATEST_VERDICTS, GET_GUARDIAN_STUDENT_VERDICT_SCALE, GET_ASSIGNMENT_ASSESSMENTS, GET_PLAN_ASSESSMENTS } from 'actions/studyplans';
var INITIAL_STATE = {
  courses: [],
  lastVerdicts: [],
  scales: null,
  verdicts: [],
  assignments: [],
  plans: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_PLAN_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: action.payload
      });
    case GET_ASSIGNMENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans: action.payload
      });
    case GET_GUARDIAN_STUDENT_LATEST_VERDICTS:
      if (action.payload != null && action.payload.length > 0) {
        var uniqueCourseSet = new Set();
        action.payload.forEach(function (verdict) {
          if (verdict.courses && verdict.courses.length > 0) {
            verdict.courses.forEach(function (course) {
              if (course && course.course) {
                uniqueCourseSet.add(JSON.stringify(course.course));
              }
            });
          }
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          lastVerdicts: action.payload,
          courses: _toConsumableArray(uniqueCourseSet).map(function (courseStr) {
            return JSON.parse(courseStr);
          })
        });
      }
      return state;
    case GET_GUARDIAN_STUDENT_VERDICT_SCALE:
      return _objectSpread(_objectSpread({}, state), {}, {
        scales: action.payload
      });
    case GET_GUARDIAN_STUDENT_VERDICTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        verdicts: action.payload
      });
    case CLEAR_GUARDIAN_STUDENT_STUDYPLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        studyPlans: [],
        lastVerdicts: [],
        scales: null,
        verdicts: []
      });
    default:
      return state;
  }
}