import api from '../lib/api';
export var GET_GUARDIAN_STUDENT_PLANS = 'GET_GUARDIAN_STUDENT_PLANS';
export var GET_GUARDIAN_STUDENT_PLAN = 'GET_GUARDIAN_STUDENT_PLAN';
export var GET_GUARDIAN_STUDENT_PLAN_ASSESSMENTS = 'GET_GUARDIAN_STUDENT_PLAN_ASSESSMENTS';
export var CLEAR_GUARDIAN_STUDENT_PLANS = 'CLEAR_GUARDIAN_STUDENT_PLANS';
export var getGuardianStudentPlans = function getGuardianStudentPlans(studentId) {
  return {
    type: GET_GUARDIAN_STUDENT_PLANS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/plans")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearGuardianStudentPlans = function clearGuardianStudentPlans() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_PLANS,
    payload: []
  };
};
export var getGuardianStudentPlan = function getGuardianStudentPlan(studentId, planId) {
  return {
    type: GET_GUARDIAN_STUDENT_PLAN,
    payload: new Promise(function (resolve, reject) {
      api.get("Guardians/me/students/".concat(studentId, "/plans/").concat(planId)).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var getGuardianStudentPlanAssessments = function getGuardianStudentPlanAssessments(planId) {
  return {
    type: GET_GUARDIAN_STUDENT_PLAN_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/v2/plans/".concat(planId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};