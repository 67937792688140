export var SET_PAGE_ACTIONS = 'HEADER_SET_PAGE_ACTIONS';
export var SET_PAGE_TITLE = 'HEADER_SET_PAGE_TITLE';
export var ON_ROUTE_CHANGE = 'HEADER_ON_ROUTE_CHANGE';
export var setPageActions = function setPageActions(value) {
  return {
    type: SET_PAGE_ACTIONS,
    payload: new Promise(function (resolve) {
      resolve(value);
    })
  };
};
export var setPageTitle = function setPageTitle(value) {
  return {
    type: SET_PAGE_TITLE,
    payload: new Promise(function (resolve) {
      resolve(value);
    })
  };
};