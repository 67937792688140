import { appInsights } from 'lib/appInsight';
export var trackImmersiveReaderError = function trackImmersiveReaderError(error, userId) {
  var localStorageSchool = localStorage.getItem("hal.school" + userId);
  var message = 'ImmersiveReaderError';
  var properties = {
    'currentSchool': localStorageSchool,
    'error': error
  };
  appInsights.trackTrace({
    message: message,
    severityLevel: 2
  }, properties);
};