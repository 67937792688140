import api from '../lib/api';
export var GET_FORM_TEMPLATE = 'TEMPLATE_GET_FORM_SINGLE_TEMPLATE';
export var CLEAR_FORM_TEMPLATE = 'TEMPLATE_CLEAR_SINGLE_FORM_TEMPLATE';
export var CREATE_FORM = 'TEMPLATE_ASSIGN_FORM_TEMPLATE';
export var UPDATE_FORM_INSTANCE = 'TEMPLATE_UPDATE_FORM_INSTANCE';
export var GET_FORM = 'TEMPLATE_GET_FORM';
export var CLEAR_FORM = 'TEMPLATE_CLEAR_FORM_DATA';
export var GET_FORMS = 'TEMPLATE_GET_FORMS';
export var GET_FORM_INSTANCE = 'TEMPLATE_GET_FORM_INSTANCE';
export var getFormTemplate = function getFormTemplate(id) {
  return {
    type: GET_FORM_TEMPLATE,
    payload: new Promise(function (resolve) {
      api.get("forms/template/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearFormTemplate = function clearFormTemplate() {
  return {
    type: CLEAR_FORM_TEMPLATE,
    payload: true
  };
};
export var getForm = function getForm(id) {
  return {
    type: GET_FORM,
    payload: new Promise(function (resolve) {
      api.get("forms/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearForm = function clearForm() {
  return {
    type: CLEAR_FORM,
    payload: true
  };
};
export var getForms = function getForms() {
  return {
    type: GET_FORMS,
    payload: new Promise(function (resolve) {
      api.get("forms").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateFormInstance = function updateFormInstance(instance) {
  return {
    type: UPDATE_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      var values = JSON.stringify(instance);
      api.put("forms/instances", values).then(function (data) {
        resolve(data);
      });
    })
  };
};
export var getFormInstance = function getFormInstance(id) {
  return {
    type: GET_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      api.get("forms/instance/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};