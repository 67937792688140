import http from '../lib/axios';
var api = {
  get: function get(route) {
    var allowAnonymous = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return http.get(route, {
      allowAnonymous: allowAnonymous
    });
  },
  put: function put(route, data) {
    return http.put(route, data, {
      headers: {
        'content-type': 'application/json'
      }
    });
  },
  post: function post(route, data) {
    return http.post(route, data, {
      headers: {
        'content-type': 'application/json'
      }
    });
  },
  "delete": function _delete(route, data) {
    return http["delete"](route, {
      data: data != null ? data : null
    });
  },
  getFile: function getFile(id) {
    return http.get("fileItems/".concat(id), {
      responseType: 'blob'
    });
  },
  getLinkFile: function getLinkFile(route) {
    var allowAnonymous = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return http.get(route, {
      allowAnonymous: allowAnonymous,
      responseType: 'blob'
    });
  }
};
export default api;