import api from '../lib/api';
export var GET_GUARDIAN_STUDENT_ASSIGNMENTS = 'GET_GUARDIAN_STUDENT_ASSIGNMENTS';
export var GET_GUARDIAN_STUDENT_ASSIGNMENT = 'GET_GUARDIAN_STUDENT_ASSIGNMENT';
export var GET_GUARDIAN_STUDENT_ASSIGNMENT_ASSESSMENTS = 'GET_GUARDIAN_STUDENT_ASSIGNMENT_ASSESSMENTS';
export var GET_GUARDIAN_ASSIGNMENT_STUDENT_ASSESSMENT = 'GET_GUARDIAN_ASSIGNMENT_STUDENT_ASSESSMENT';
export var CLEAR_GUARDIAN_STUDENT_ASSIGNMENTS = 'CLEAR_GUARDIAN_STUDENT_ASSIGNMENTS';
export var GET_TEAMS_ASSIGNMENTS = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENTS';
export var GET_TEAMS_ASSIGMENT = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT';
export var GET_TEAMS_ASSIGMENT_OUTCOMES = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT_OUTCOMES';
export var GET_TEAMS_ASSIGMENT_RESOURCES = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT_RESOURCES';
export var GET_TEAMS_ASSIGMENT_RESOURCE = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT_RESOURCE';
export var getGuardianStudentAssignments = function getGuardianStudentAssignments(studentId) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/assignments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearGuardianStudentAssignments = function clearGuardianStudentAssignments() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_ASSIGNMENTS,
    payload: []
  };
};
export var getGuardianStudentAssignment = function getGuardianStudentAssignment(studentId, assignmentId) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSIGNMENT,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/assignments/").concat(assignmentId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssignmentAssessments = function getGuardianStudentAssignmentAssessments(assignmentTaskId) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSIGNMENT_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/v2/assignments/".concat(assignmentTaskId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessments = function getGuardianStudentAssessments(studentId, assignmentTaskId) {
  return {
    type: GET_GUARDIAN_ASSIGNMENT_STUDENT_ASSESSMENT,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/assignments/").concat(assignmentTaskId, "/studentassessment")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamsAssignments = function getTeamsAssignments(studentId) {
  return {
    type: GET_TEAMS_ASSIGNMENTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/MSAssignments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamsAssignment = function getTeamsAssignment(studentId, groupId, assignmentId) {
  return {
    type: GET_TEAMS_ASSIGMENT,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/Groups/").concat(groupId, "/MSAssignments/").concat(assignmentId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamsAssignmentOutcomes = function getTeamsAssignmentOutcomes(studentId, groupId, assignmentId) {
  return {
    type: GET_TEAMS_ASSIGMENT_OUTCOMES,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/Groups/").concat(groupId, "/MSAssignments/").concat(assignmentId, "/outcomes")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamsAssignmentResources = function getTeamsAssignmentResources(studentId, groupId, assignmentId) {
  return {
    type: GET_TEAMS_ASSIGMENT_RESOURCES,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/Groups/").concat(groupId, "/MSAssignments/").concat(assignmentId, "/resources")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getTeamsAssignmentResource = function getTeamsAssignmentResource(studentId, groupId, assignmentId, resourceId, fileName) {
  return {
    type: GET_TEAMS_ASSIGMENT_RESOURCE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/Groups/").concat(groupId, "/MSAssignments/").concat(assignmentId, "/resources/").concat(resourceId, "/fileUrl")).then(function (response) {
        var type = response.headers['content-type'];
        var url = response.data;
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); //or any other extension
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      });
    })
  };
};