import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENT_GROUPS, GET_GUARDIAN_STUDENT_GROUP, GET_GUARDIAN_MENTOR_GROUPS, GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS, GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE, GET_GUARDIAN_STUDENT_ASSESSMENTS_DATE, GET_GUARDIAN_MATRIX_ASSESSMENT_STATISTIC, GET_GUARDIAN_STUDENT_ASSESSMENTS_PROGRESS, GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_MATRIX_DATE, GET_GUARDIAN_STUDENT_ASSESSMENTS_STATISTIC_PROGRESS, GET_GUARDIAN_STUDENT_ASSESSMENTS, CLEAR_GUARDIAN_STUDENT_GROUPS, GET_GUARDIAN_STUDENT_ASSESSMENTS_BY_SECTION, GET_COURSES } from 'actions/sections';
var INITIAL_STATE = {
  groups: [],
  activeGroup: null,
  activeGroupAssessments: null,
  matrixAssessments: [],
  studentOverview: [],
  progressSections: [],
  progressAssessmentStats: [],
  studentAssessments: [],
  mentorGroups: null,
  courses: []
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_GUARDIAN_STUDENT_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: action.payload
      });
    case GET_GUARDIAN_MENTOR_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        mentorGroups: action.payload
      });
    case CLEAR_GUARDIAN_STUDENT_GROUPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        groups: [],
        mentorGroups: null,
        courses: [],
        activeGroup: null,
        activeGroupAssessments: null,
        matrixAssessments: [],
        studentOverview: [],
        progressSections: [],
        progressAssessmentStats: [],
        studentAssessments: []
      });
    case GET_GUARDIAN_STUDENT_GROUP:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeGroup: action.payload
      });
    case GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeGroupAssessments: action.payload
      });
    case GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentOverview: action.payload
      });
    case GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentOverview: action.payload
      });
    case GET_GUARDIAN_STUDENT_ASSESSMENTS_DATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentOverview: action.payload
      });
    case GET_GUARDIAN_MATRIX_ASSESSMENT_STATISTIC:
      return _objectSpread(_objectSpread({}, state), {}, {
        matrixAssessments: action.payload
      });
    case GET_GUARDIAN_STUDENT_ASSESSMENTS_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        progressSections: action.payload
      });
    case GET_GUARDIAN_STUDENT_ASSESSMENTS_STATISTIC_PROGRESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        progressAssessmentStats: action.payload
      });
    case GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_MATRIX_DATE:
      if (action.payload == null) {
        return state;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        matrixAssessments: [].concat(_toConsumableArray(state.matrixAssessments), [action.payload])
      });
    case GET_GUARDIAN_STUDENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAssessments: action.payload
      });
    case GET_GUARDIAN_STUDENT_ASSESSMENTS_BY_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        studentAssessments: action.payload
      });
    case GET_COURSES:
      return _objectSpread(_objectSpread({}, state), {}, {
        courses: [].concat(_toConsumableArray(state.courses), [action.payload])
      });
    default:
      return state;
  }
}