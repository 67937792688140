import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { setupInterceptors } from "../lib/axios";
function injectAxiosInterceptors() {
  var history = useHistory();
  useEffect(function () {
    setupInterceptors(history);
  }, [history]);

  // not rendering anything
  return null;
}
export default injectAxiosInterceptors;