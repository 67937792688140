import api from 'lib/api';
export var GET_MEETING = 'GET_MEETING';
export var GET_MEETINGS = 'GET_MEETINGS';
export var GET_MEETING_INSTANCE = 'GET_MEETING_INSTANCE';
export var GET_MEETING_INSTANCES = 'GET_MEETING_INSTANCES';
export var CREATE_MEETING_FORM_INSTANCE = 'CREATE_MEETING_FORM_INSTANCE';
export var REQUEST_MEETING_INSTANCE_CALENDAR_INVITE = 'MEETINGS_REQUEST_MEETING_INSTANCE_CALENDAR_INVITE';
export var CLEAR_MEETINGS = 'CLEAR_MEETINGS';
export var getMeetings = function getMeetings(user) {
  return {
    type: GET_MEETINGS,
    payload: new Promise(function (resolve) {
      api.get("meetings").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMeeting = function getMeeting(id) {
  return {
    type: GET_MEETING,
    payload: new Promise(function (resolve) {
      api.get("meetings/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMeetingInstance = function getMeetingInstance(id) {
  return {
    type: GET_MEETING_INSTANCE,
    payload: new Promise(function (resolve) {
      api.get("meetings/instances/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var updateMeetingInstanceDate = function updateMeetingInstanceDate(obj, id) {
  return {
    type: GET_MEETING_INSTANCE,
    payload: new Promise(function (resolve, reject) {
      var json = JSON.stringify(obj);
      api.put("meetings/instances/".concat(id, "/dates"), json).then(function (response) {
        if (response.status === 204) {
          resolve(response.data);
        } else {
          resolve(response.data);
        }
      })["catch"](function (error) {
        reject(null);
      });
    })
  };
};
export var createMeetingFormInstance = function createMeetingFormInstance(meetingId, instanceId, instance) {
  return {
    type: CREATE_MEETING_FORM_INSTANCE,
    payload: new Promise(function (resolve) {
      var values = JSON.stringify(instance);
      api.post("meetings/".concat(meetingId, "/instance/").concat(instanceId, "/formInstance"), values).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var requestMeetingCalendarInvite = function requestMeetingCalendarInvite(meetingInstanceId) {
  return {
    type: REQUEST_MEETING_INSTANCE_CALENDAR_INVITE,
    payload: new Promise(function (resolve, reject) {
      api.post("meetings/instances/".concat(meetingInstanceId, "/sendappointment")).then(function (response) {
        resolve(response.data);
      })["catch"](function (error) {
        reject(error);
      });
    })
  };
};
export var clearMeetings = function clearMeetings() {
  return {
    type: CLEAR_MEETINGS,
    payload: []
  };
};