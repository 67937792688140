import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { ADD_ERROR } from '../actions/';
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ADD_ERROR:
      return [].concat(_toConsumableArray(state), [action.payload]);
    default:
      return state;
  }
}