import api from "lib/api";
export var GET_ASSESSMENT = "GET_ASSESSMENT";
export var GET_ASSESSMENTS = "GET_ASSESSMENTS";
export var CREATE_ASSESSMENTS = "CREATE_ASSESSMENTS";
export var DELETE_ASSESSMENT = "ASSESSMENTS_DELETE_ASSESSMENTS";
export var DELETE_ASSESSMENTS_DETAIL = "ASSESSMENTS_DELETE_ASSESSMENTS_DETAIL";
export var GET_STUDENT_ASSESSMENTS = "GET_STUDENT_ASSESSMENTS";
export var UPDATE_ASSESSMENT = "UPDATE_ASSESSMENT";
export var UPDATE_ASSESSMENT_STATUS = "UPDATE_ASSESSMENT_STATUS";
export var GET_STUDENT_ASSESSMENT_OVERVIEW = "GET_STUDENT_ASSESSMENT_OVERVIEW";
export var GET_MATRIX_ASSESSMENT_STATISTIC = "GET_MATRIX_ASSESSMENT_STATISTIC";
export var GET_STUDENT_COURSES_GOALS = "ASSESSMENTS_GET_STUDENT_COURSES_AND_GOALS";
export var GET_STUDENT_ASSESSMENT_STATISTICS = "ASSESSMENTS_GET_STUDENT_ASSESSMENT_STATISTICS";
export var RESET_MATRIX_ASSESSMENT_STATISTICS = "RESET_MATRIX_ASSESSMENT_STATISTICS";
export var CLEAR_MATRIX_ASSESSMENT_OVERVIEW = "CLEAR_MATRIX_ASSESSMENT_OVERVIEW";
export var RESET_USER_ASESSMENT_STATISTIC = "RESET_USER_ASSESSMENT_STATISTIC";
export var GET_SECTIONS_FROM_ASSESSMENTS = "GET_SECTIONS_FROM_ASSESSMENTS";
export var GET_USER_ASSESSMENTS_STATISTIC_PROGRESS = "GET_USER_ASSESSMENTS_STATISTIC_PROGRESS";
export var GET_ASSESSMENTS_BY_STUDENT = "GET_ASSESSMENTS_BY_STUDENT";
export var GET_ASSESSMENTS_BY_STUDENT_AND_SECTION = "GET_ASSESSMENTS_BY_STUDENT_AND_SECTION";
export var CLEAR_ASSESSMENTS = "CLEAR_ASSESSMENTS";
export var EXPORT_ASSESSMENT = "ASSESSMENTS_EXPORT_ASSESSMENT";
export var EXPORT_ASSESSMENT_FOR_SECTION = "EXPORT_ASSESSMENT_FOR_SECTION";
export var EXPORT_ASSESSMENT_FOR_SCHOOLID = "EXPORT_ASSESSMENT_FOR_SCHOOLID";
export var getPlanAssessments = function getPlanAssessments(id) {
  return {
    type: GET_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/v2/plans/".concat(id)).then(function (response) {
        if (response.status > 300) {
          resolve([]);
          return false;
        }
        resolve(response.data);
      });
    })
  };
};
export var getAssignmentAssessment = function getAssignmentAssessment(id) {
  return {
    type: GET_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/assignment/".concat(id)).then(function (response) {
        if (response.status != 200) {
          resolve(null);
        } else {
          resolve(response.data);
        }
      });
    })
  };
};
export var clearMatrixAssessmentOverview = function clearMatrixAssessmentOverview() {
  return {
    type: CLEAR_MATRIX_ASSESSMENT_OVERVIEW,
    payload: true
  };
};
export var getUserAssessmentStatistics = function getUserAssessmentStatistics(studentId, start, end) {
  return {
    type: GET_STUDENT_ASSESSMENT_OVERVIEW,
    payload: new Promise(function (resolve) {
      api.get("assessments/student/".concat(studentId, "/start/").concat(start, "/end/").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getSectionsFromAssessments = function getSectionsFromAssessments(userId, startDate, endDate) {
  return {
    type: GET_SECTIONS_FROM_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("assessments/".concat(userId, "/sections/start/").concat(startDate, "/end/").concat(endDate)).then(function (response) {
        if (response.status == 204) {
          resolve(null);
          return true;
        }
        resolve(response.data);
      });
    })
  };
};
export var getUserSummary = function getUserSummary(studentId, startDate, endDate) {
  var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return {
    type: GET_STUDENT_COURSES_GOALS,
    payload: new Promise(function (resolve, reject) {
      var url = "assessments/v2/users/".concat(studentId, "/summary/data?startDate=").concat(startDate, "&endDate=").concat(endDate);
      if (params.sectionId != null) {
        url += "&sectionId=".concat(params.sectionId);
      }
      api.get(url).then(function (response) {
        resolve(response.data);
      })["catch"](function (e) {
        reject(e);
      });
    })
  };
};
export var getUserStatisticsDetailed = function getUserStatisticsDetailed(studentId, startDate, endDate) {
  var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  return {
    type: GET_STUDENT_ASSESSMENT_STATISTICS,
    payload: new Promise(function (resolve, reject) {
      var url = "assessments/v2/users/".concat(studentId, "/statistics/detailed?startDate=").concat(startDate, "&endDate=").concat(endDate);
      if (params.sectionId != null) {
        url += "&sectionId=".concat(params.sectionId);
      }
      api.get(url).then(function (response) {
        resolve(response.data);
      })["catch"](function (e) {
        reject(e);
      });
    })
  };
};
export var clearAssessments = function clearAssessments() {
  return {
    type: CLEAR_ASSESSMENTS,
    payload: []
  };
};