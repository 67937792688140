import _typeof from "@babel/runtime/helpers/typeof";
import moment from 'moment';
export var setMenuItems = function setMenuItems(items) {
  if (items != null) {
    localStorage.setItem('menu_items', JSON.stringify(items));
  }
};
export var getMenuItems = function getMenuItems() {
  var items = localStorage.getItem('menu_items');
  if (items != null && items != '') {
    var decoded = JSON.parse(items);
    if (decoded.length > 0) {
      return decoded;
    }
    return null;
  }
  return null;
};
export var getSchoolType = function getSchoolType(activeStudent) {
  if (activeStudent != null) {
    var schoolTypes = [];
    activeStudent.schools.forEach(function (school) {
      schoolTypes.push(school.type);
    });
    if (schoolTypes.length > 0) {
      return schoolTypes[0].toUpperCase();
    } else {
      return null;
    }
  }
  return null;
};
export var saveWithExpiration = function saveWithExpiration(key, value) {
  var expiration = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (expiration == null) {
    expiration = moment().add(12, 'hours').format();
  } else {
    expiration = moment(expiration).format();
  }
  var item = {
    data: value,
    expiration: expiration
  };
  localStorage.setItem(key, JSON.stringify(item));
};
export var getWithExpiration = function getWithExpiration(key) {
  var item = localStorage.getItem(key);
  if (item == null) {
    return null;
  }
  var obj = null;
  try {
    obj = JSON.parse(item);
  } catch (e) {
    obj = item;
  }
  if (typeof obj == 'string') {
    return obj;
  }
  if (_typeof(obj) == 'object') {
    if (moment().isSameOrAfter(moment(obj.expiration))) {
      // Item has expired
      localStorage.removeItem(key);
      return null;
    }
    return obj.data;
  }
  return null;
};