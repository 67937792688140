/* Import dependencies */
import api from '../lib/api';

/* Define action type */
export var FETCH_PRESCHOOL_GOALS = 'FETCH_PRESCHOOL_GOALS';
export var GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'PRESCHOOL_GOALS_GET_GOAL_PLANS_AND_DOCUMENTATIONS';
export var RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS';
export var GET_PRESCHOOL_GOALS_USER_STATISTICS = 'PRESCHOOL_GOALS_GET_USER_STATISTICS';
export function fetch_preschoolgoals() {
  return {
    type: FETCH_PRESCHOOL_GOALS,
    payload: new Promise(function (resolve, reject) {
      api.get("base/goals").then(function (response) {
        resolve(response.data);
      });
    })
  };
}
export var getLinkedPlansAndDocumententationsForGoal = function getLinkedPlansAndDocumententationsForGoal(goalId, from, to) {
  return {
    type: GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
    payload: new Promise(function (resolve) {
      api.get("goals/".concat(goalId, "/plansAndDocumentations?from=").concat(from, "&to=").concat(to)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var resetLinkedPlansAndDocumentations = function resetLinkedPlansAndDocumentations() {
  return {
    type: RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
    payload: true
  };
};
export var getGoalsStatisticsForUser = function getGoalsStatisticsForUser(studentId, start, end) {
  return {
    type: GET_PRESCHOOL_GOALS_USER_STATISTICS,
    payload: new Promise(function (resolve) {
      api.get("goals/statistics?from=".concat(start, "&to=").concat(end, "&fromStudentCard=1")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};