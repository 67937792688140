import packageJson from './../../package.json';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
var configSettings = require('../../app-config/config');
var config = configSettings.GET_CONFIG();
var instrumentationKey = config.AIKEY;
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    disableExceptionTracking: false
  }
});
appInsights.loadAppInsights();
appInsights.context.application.ver = packageJson.version;
export { appInsights };