import api from '../lib/api';
export var GET_PROFILE_INFORMATION = 'GET_PROFILE_INFORMATION';
export var PUT_PROFILE_INFORMATION = 'PUT_PROFILE_INFORMATION';
export var putProfileInformation = function putProfileInformation(profile) {
  return {
    type: PUT_PROFILE_INFORMATION,
    payload: new Promise(function (resolve) {
      var json = JSON.stringify(profile);
      api.put('profile/me', json).then(function (data) {
        resolve(data);
      });
    })
  };
};
export var getProfileInformation = function getProfileInformation() {
  return {
    type: GET_PROFILE_INFORMATION,
    payload: new Promise(function (resolve) {
      api.get('profile/me').then(function (response) {
        resolve(response.data);
      });
    })
  };
};