import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_GUARDIAN_STUDENT_ASSIGNMENTS, GET_GUARDIAN_STUDENT_ASSIGNMENT, GET_GUARDIAN_STUDENT_ASSIGNMENT_ASSESSMENTS, GET_GUARDIAN_ASSIGNMENT_STUDENT_ASSESSMENT, CLEAR_GUARDIAN_STUDENT_ASSIGNMENTS, GET_TEAMS_ASSIGNMENTS, GET_TEAMS_ASSIGMENT, GET_TEAMS_ASSIGMENT_OUTCOMES, GET_TEAMS_ASSIGMENT_RESOURCES, GET_TEAMS_ASSIGMENT_RESOURCE } from 'actions/assignments';
import Moment from 'moment';
var INITIAL_STATE = {
  assignments: [],
  current: [],
  submitted: [],
  overdue: [],
  upcoming: [],
  assignment: null,
  assessments: null,
  outcomes: null,
  selfAssessment: null,
  assignmentResources: null,
  assignmentResource: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_GUARDIAN_STUDENT_ASSIGNMENTS:
      var overdue = getOverdue(action.payload, state);
      var submitted = getSubmitted(action.payload, state);
      var upcoming = getUpcoming(action.payload, state);
      var current = getCurrent(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: [].concat(_toConsumableArray(state.assignments), _toConsumableArray(action.payload)),
        overdue: overdue,
        submitted: submitted,
        upcoming: upcoming,
        current: current
      });
    case GET_TEAMS_ASSIGNMENTS:
      var overdueTeams = getOverdue(action.payload, state);
      var submittedTeams = getSubmitted(action.payload, state);
      var upcomingTeams = getUpcoming(action.payload, state);
      var currentTeams = getCurrent(action.payload, state);
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: [].concat(_toConsumableArray(state.assignments), _toConsumableArray(action.payload)),
        overdue: overdueTeams,
        submitted: submittedTeams,
        upcoming: upcomingTeams,
        current: currentTeams
      });
    case CLEAR_GUARDIAN_STUDENT_ASSIGNMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignments: [],
        current: [],
        submitted: [],
        overdue: [],
        upcoming: [],
        assignment: null,
        assessments: null,
        outcomes: null,
        selfAssessment: null,
        assignmentResources: null,
        assignmentResource: null
      });
    case GET_GUARDIAN_STUDENT_ASSIGNMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignment: action.payload
      });
    case GET_GUARDIAN_STUDENT_ASSIGNMENT_ASSESSMENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assessments: action.payload
      });
    case GET_GUARDIAN_ASSIGNMENT_STUDENT_ASSESSMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selfAssessment: action.payload
      });
    case GET_TEAMS_ASSIGMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignment: action.payload
      });
    case GET_TEAMS_ASSIGMENT_OUTCOMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        outcomes: action.payload
      });
    case GET_TEAMS_ASSIGMENT_RESOURCES:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignmentResources: action.payload
      });
    case GET_TEAMS_ASSIGMENT_RESOURCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignmentResource: action.payload
      });
    default:
      return state;
  }
}
function getOverdue(payload, state) {
  var assignments = [];
  var timeNow = Moment();
  if (payload != null) {
    assignments.push.apply(assignments, _toConsumableArray(payload));
  }
  var list = state.overdue;
  for (var _i = 0, _assignments = assignments; _i < _assignments.length; _i++) {
    var assignment = _assignments[_i];
    var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
    var isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());
    if (assignment.assignmentType == 'TEAMS') {
      if (assignment.status == 'working' && !isBefore && isAfter) {
        list.push(assignment);
      }
    } else {
      if (assignment.status == "ASSIGNMENT_OPEN" && !isBefore && isAfter) {
        if (assignment.tasks.length > 0 && assignment.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED') {
          list.push(assignment);
        }
      }
    }
  }
  sortList(list);
  return list;
}
function getCurrent(payload, state) {
  var assignments = [];
  var timeNow = Moment();
  if (payload != null) {
    assignments.push.apply(assignments, _toConsumableArray(payload));
  }
  var list = state.current;
  for (var _i2 = 0, _assignments2 = assignments; _i2 < _assignments2.length; _i2++) {
    var assignment = _assignments2[_i2];
    var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
    var isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());
    if (assignment.assignmentType == 'TEAMS') {
      if (assignment.status == 'working' && !isBefore && !isAfter) {
        list.push(assignment);
      }
    } else {
      if (assignment.status == "ASSIGNMENT_OPEN" && !isBefore && !isAfter) {
        if (assignment.tasks[0] != null && assignment.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED') {
          list.push(assignment);
        }
      }
    }
  }
  sortListDesc(list);
  return list;
}
function getUpcoming(payload, state) {
  var assignments = [];
  var timeNow = Moment();
  if (payload != null) {
    assignments.push.apply(assignments, _toConsumableArray(payload));
  }
  var list = state.upcoming;
  for (var _i3 = 0, _assignments3 = assignments; _i3 < _assignments3.length; _i3++) {
    var assignment = _assignments3[_i3];
    if (assignment.id == parseInt(assignment.id, 10)) {
      var isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
      if (assignment.status == 'ASSIGNMENT_OPEN' && isBefore) {
        if (list.findIndex(function (t) {
          return t.id == assignment.id;
        }) == -1) {
          list.push(assignment);
        }
      }
    } else {
      if (assignment.status == 'assigned' && assignment.assignedDateTime != null && timeNow.isBefore(Moment(assignment.assignedDateTime))) {
        list.push(assignment);
      }
    }
  }
  sortList(list);
  return list;
}
function getSubmitted(payload, state) {
  var assignments = [];
  if (payload != null) {
    assignments.push.apply(assignments, _toConsumableArray(payload));
  }
  var list = state.submitted;
  assignments.forEach(function (assignment) {
    if (assignment.assignmentType == 'TEAMS') {
      if (assignment.status == 'returned' || assignment.status == 'submitted') {
        list.push(assignment);
      }
    } else {
      if (assignment.tasks != null && assignment.tasks.length > 0 && assignment.tasks[0] != null && assignment.tasks[0].status == "ASSIGNMENT_TASK_SUBMITTED") {
        list.push(assignment);
      }
    }
  });
  sortList(list);
  return list;
}
function sortList(list) {
  list.sort(function (a, b) {
    var dateA = a.dueDate;
    var dateB = b.dueDate;
    if (a.displayName != null) {
      dateA = a.dueDateTime;
    }
    if (b.displayName != null) {
      dateB = b.dueDateTime;
    }
    return Moment(dateB).unix() - Moment(dateA).unix();
  });
}
function sortListDesc(list) {
  list.sort(function (a, b) {
    var dateA = a.dueDate;
    var dateB = b.dueDate;
    if (a.displayName != null) {
      dateA = a.dueDateTime;
    }
    if (b.displayName != null) {
      dateB = b.dueDateTime;
    }
    return Moment(dateA).unix() - Moment(dateB).unix();
  });
}