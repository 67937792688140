export var getAttendanceColor = function getAttendanceColor(attendance) {
  var color = '#fff';
  if (attendance != null) {
    if (attendance.attendanceType == 'PRESENT') {
      color = '#19DB6C';
    }
    if (attendance.attendanceType == 'INVALID_ABSENCE') {
      color = '#F25252';
    }
    if (attendance.attendanceType == 'ABSENT') {
      color = '#F25252';
    }
    if (attendance.attendanceType == 'VALID_ABSENCE') {
      color = '#FF883E';
    }
    if (attendance.attendanceType == 'INVALID_LATE') {
      color = '#F2527D';
    }
    if (attendance.attendanceType == 'LATE') {
      color = '#F2527D';
    }
    if (attendance.attendanceType == 'VALID_LATE') {
      color = '#EFC24C';
    }
  }
  return color;
};