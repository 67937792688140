var STUDENT_ID = 'guardians_active_student';
var SCHOOL_ID = 'guardians_active_school';
export var setActiveStudent = function setActiveStudent(student) {
  if (student != null) {
    localStorage.setItem(STUDENT_ID, student);
    return true;
  } else {
    return false;
  }
};
export var getActiveStudent = function getActiveStudent() {
  return localStorage.getItem(STUDENT_ID);
};
export var setActiveSchool = function setActiveSchool(school, student) {
  if (school != null) {
    localStorage.setItem(SCHOOL_ID + '-' + student, school);
    return true;
  } else {
    return false;
  }
};
export var getActiveSchool = function getActiveSchool(student) {
  return localStorage.getItem(SCHOOL_ID + '-' + student);
};