import api from '../lib/api';
export var GET_STUDENT_ADJUSTMENTS = 'ADJUSTMENTS_GET_STUDENT_ADJUSTMENTS';
export var CLEAR_STUDENT_ADJUSTMENTS = 'CLEAR_STUDENT_ADJUSTMENTS';
export var getStudentAdjustments = function getStudentAdjustments(student) {
  return {
    type: GET_STUDENT_ADJUSTMENTS,
    payload: new Promise(function (resolve) {
      api.get('StudentAdditionalAdjustments').then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearStudentAdjustments = function clearStudentAdjustments() {
  return {
    type: CLEAR_STUDENT_ADJUSTMENTS,
    payload: []
  };
};