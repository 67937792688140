import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import api from '../lib/api';
import Moment from 'moment';
var MESSAGES_PER_PAGE = 50;
export var GET_CONVERSATIONS = 'CONVERSATIONS_GET_CONVERSATIONS';
export var GET_CONVERSATION = 'CONVERSATIONS_GET_CONVERSATION';
export var GET_CONVERSATIONS_REFERENCE = 'CONVERSATIONS_GET_CONVERSATIONS_BY_REFERENCE';
export var RESET_CONVERSATION = 'CONVERSATIONS_RESET_CONVERSATION';
export var GET_CONVERSATION_MESSAGES = 'CONVERSATION_GET_CONVERSATION_MESSAGES';
export var GET_USER_CONTACTS = 'CONVERSATION_GET_USER_CONTACTS';
export var GET_UNREAD_COUNT = 'CONVERSATION_GET_USER_UNREAD_COUNT';
export var SEARCH_CONVERSATIONS = 'CONVERSATION_SEARCH_CONVERSATIONS';
export var CLEAR_SEARCH_CONVERSATIONS = 'CONVERSATION_CLEAR_SEARCH_CONVERSATIONS';
export var FLAG_MESSAGE = 'CONVERSATION_FLAG_MESSAGE';
export var STAGE_CONVERSATION = 'CONVERSATION_STAGE_NEW_CONVERSATION';
export var SUBMIT_CONVERSATION = 'CONVERSATION_SUBMIT_NEW_CONVERSATION';
export var SUBMIT_CONVERSATION_MESSAGE = 'CONVERSATION_SUBMIT_CONVERSATION_MESSAGE';
export var getConversations = function getConversations(studentId) {
  return {
    type: GET_CONVERSATIONS,
    payload: new Promise(function (resolve) {
      api.get("conversations").then(function (response) {
        var sortItems = function sortItems(a, b) {
          if (a.messages.length > 0 && b.messages.length > 0) return Moment(b.messages[0].created).unix() - Moment(a.messages[0].created).unix();else return 0;
        };
        response.data.sort(sortItems);
        resolve(response.data);
      });
    })
  };
};
export var getConversation = function getConversation(id) {
  return {
    type: GET_CONVERSATION,
    payload: new Promise(function (resolve) {
      api.get("conversations/".concat(id)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getConversationsByReference = function getConversationsByReference(referenceId, referenceType, student) {
  return {
    type: GET_CONVERSATIONS_REFERENCE,
    payload: new Promise(function (resolve) {
      api.get("conversations?referenceId=".concat(referenceId, "&referenceType=").concat(referenceType)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getMessages = function getMessages(conversation, page) {
  return {
    type: GET_CONVERSATION_MESSAGES,
    payload: new Promise(function (resolve) {
      var skip = page * MESSAGES_PER_PAGE;
      var top = skip + MESSAGES_PER_PAGE;
      api.get("conversations/".concat(conversation, "/messages?skip=").concat(skip, "&top=").concat(top)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var submitMessage = function submitMessage(message) {
  return {
    type: SUBMIT_CONVERSATION_MESSAGE,
    payload: new Promise(function (resolve) {
      var data = JSON.stringify(message);
      api.post('conversations/message', data).then(function (data) {
        var response = _objectSpread(_objectSpread({}, message), {}, {
          id: data.data
        });
        resolve(response);
      });
    })
  };
};
export var resetConversation = function resetConversation() {
  return {
    type: RESET_CONVERSATION,
    payload: true
  };
};
export var createConversation = function createConversation(conversation, studentId) {
  return {
    type: SUBMIT_CONVERSATION,
    payload: new Promise(function (resolve) {
      if (conversation.id != null) {
        delete conversation.id;
      }
      var data = JSON.stringify(conversation);
      api.post("conversations", data).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var stageConversation = function stageConversation(conversation) {
  return {
    type: STAGE_CONVERSATION,
    payload: new Promise(function (resolve) {
      resolve(conversation);
    })
  };
};
export var getContacts = function getContacts(student) {
  return {
    type: GET_USER_CONTACTS,
    payload: new Promise(function (resolve) {
      api.get("conversations/contacts/".concat(student)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getUnreadMessages = function getUnreadMessages(studentId) {
  return {
    type: GET_UNREAD_COUNT,
    payload: new Promise(function (resolve) {
      api.get("conversations/unread").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var searchConversation = function searchConversation(query, studentId) {
  return {
    type: SEARCH_CONVERSATIONS,
    payload: new Promise(function (resolve) {
      api.get("conversations/?search=".concat(query)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearSearch = function clearSearch() {
  return {
    type: CLEAR_SEARCH_CONVERSATIONS,
    payload: true
  };
};
export var flagMessage = function flagMessage(id, reason) {
  return {
    type: FLAG_MESSAGE,
    payload: new Promise(function (resolve) {
      var values = {
        'messageId': id,
        'reason': reason
      };
      api.post("conversations/flagged", values).then(function (response) {
        resolve(_objectSpread({
          flagID: response.data
        }, values));
      });
    })
  };
};