/* Import React libraries */
import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import absence from "./reducer_absence";
import assignments from "./reducer_assignments";
import attendance from "./reducer_attendance";
import base from "./reducer_base";
import Conversation from "./reducer_conversation";
import documents from "./reducer_documents";
import ErrorReducer from "./reducer_error";
import GroupsReducer from "./reducer_groups";
import guardians from "./reducer_guardians";
import Meetings from "./reducer_meetings";
import notifications from "./reducer_notifications";
import planning from "./reducer_planning";
import posts from "./reducer_posts";
import profile from "./reducer_profile";
import studyplans from "./reducer_studyplans";
import Templates from "./reducer_templates";
import User from "./reducer_user";
import DeviceInfo from "./reducer_deviceinfo";
import Menu from "./reducer_menu";
import Header from "./reducer_header";
import AdditionalAdjustments from "./reducer_adjustments";
import TimeBlocks from "./reducer_timeblocks";
import Notifications from "./reducer_notifications";
import PreschoolGoals from "./reducer_preschoolgoal";
import Languages from "./reducer_languages";
import Blocks from "./reducer_blocks";
import assessments from "./reducer_assessments";
import ScheduleFilter from "./reducer_scheduleFilter";
var rootReducer = combineReducers({
  form: reduxFormReducer,
  absence: absence,
  attendance: attendance,
  notifications: notifications,
  errors: ErrorReducer,
  groups: GroupsReducer,
  guardians: guardians,
  profile: profile,
  base: base,
  documents: documents,
  assignments: assignments,
  planning: planning,
  posts: posts,
  studyplans: studyplans,
  Conversation: Conversation,
  Templates: Templates,
  Meetings: Meetings,
  User: User,
  Menu: Menu,
  Header: Header,
  AdditionalAdjustments: AdditionalAdjustments,
  TimeBlocks: TimeBlocks,
  Notifications: Notifications,
  DeviceInfo: DeviceInfo,
  PreschoolGoals: PreschoolGoals,
  Languages: Languages,
  Blocks: Blocks,
  assessments: assessments,
  ScheduleFilter: ScheduleFilter
});
export default rootReducer;