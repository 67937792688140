export var CLEAR_FILTER = "CLEAR_FILTER";
export var UPDATE_FILTER = "UPDATE_FILTER";
export var clearScheduleFilter = function clearScheduleFilter() {
  return {
    type: "CLEAR_FILTER"
  };
};
export var UpdateFilter = function UpdateFilter(data, obj) {
  return {
    type: "UPDATE_FILTER",
    payload: {
      allData: data,
      obj: obj
    }
  };
};