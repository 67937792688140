import api from '../lib/api';
export var GET_GUARDIAN_STUDENT_GROUPS = 'GET_GUARDIAN_STUDENT_GROUPS';
export var GET_GUARDIAN_STUDENT_GROUP = 'GET_GUARDIAN_STUDENT_GROUP';
export var GET_GUARDIAN_MENTOR_GROUPS = 'GET_GUARDIAN_MENTOR_GROUPS';
export var GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS = 'GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS';
export var GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE = 'GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE';
export var GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_MATRIX_DATE = 'GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_MATRIX_DATE';
export var GET_GUARDIAN_STUDENT_ASSESSMENTS_DATE = 'GET_GUARDIAN_STUDENT_ASSESSMENTS_DATE';
export var GET_GUARDIAN_MATRIX_ASSESSMENT_STATISTIC = 'GET_GUARDIAN_MATRIX_ASSESSMENT_STATISTIC';
export var GET_GUARDIAN_STUDENT_ASSESSMENTS_PROGRESS = 'GET_GUARDIAN_STUDENT_ASSESSMENTS_PROGRESS';
export var GET_GUARDIAN_STUDENT_ASSESSMENTS_STATISTIC_PROGRESS = 'GET_GUARDIAN_STUDENT_ASSESSMENTS_STATISTIC_PROGRESS';
export var GET_GUARDIAN_STUDENT_ASSESSMENTS = 'GET_GUARDIAN_STUDENT_ASSESSMENTS';
export var CLEAR_GUARDIAN_STUDENT_GROUPS = 'CLEAR_GUARDIAN_STUDENT_GROUPS';
export var GET_GUARDIAN_STUDENT_ASSESSMENTS_BY_SECTION = 'GET_GUARDIAN_STUDENT_ASSESSMENTS_BY_SECTION';
export var GET_COURSES = 'SECTIONS_GET_COURSES';
export var getStudentEducationGroups = function getStudentEducationGroups(studentId) {
  return {
    type: GET_GUARDIAN_STUDENT_GROUPS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Sections/type/EDUCATION_GROUP")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentMentorGroups = function getStudentMentorGroups(studentId) {
  return {
    type: GET_GUARDIAN_MENTOR_GROUPS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Sections/type/MENTOR_GROUP")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentGroup = function getGuardianStudentGroup(studentId, groupId) {
  return {
    type: GET_GUARDIAN_STUDENT_GROUP,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/Sections/").concat(groupId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessmentsByGroup = function getGuardianStudentAssessmentsByGroup(studentId, groupId) {
  return {
    type: GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/section/").concat(groupId, "/assessments")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessmentsByGroupAndDate = function getGuardianStudentAssessmentsByGroupAndDate(studentId, groupId, start, end) {
  return {
    type: GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/section/").concat(groupId, "/assessments/start/").concat(start, "/end/").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessmentsByDate = function getGuardianStudentAssessmentsByDate(studentId, start, end) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSESSMENTS_DATE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/assessments/start/").concat(start, "/end/").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentMatrixAssessmentsByDate = function getGuardianStudentMatrixAssessmentsByDate(studentId, matrixId, start, end) {
  return {
    type: GET_GUARDIAN_MATRIX_ASSESSMENT_STATISTIC,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/matrix/").concat(matrixId, "/start/").concat(start, "/end/").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearMatrixAssessmentOverview = function clearMatrixAssessmentOverview() {
  return {
    type: GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_DATE,
    payload: []
  };
};
export var clearGuardianStudentGroups = function clearGuardianStudentGroups() {
  return {
    type: CLEAR_GUARDIAN_STUDENT_GROUPS,
    payload: []
  };
};
export var getGuardianStudentAssessmentsByGroupAndMatrixDate = function getGuardianStudentAssessmentsByGroupAndMatrixDate(studentId, groupId, matrixId, start, end) {
  return {
    type: GET_GUARDIAN_STUDENT_GROUP_ASSESSMENTS_MATRIX_DATE,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/Students/".concat(studentId, "/section/").concat(groupId, "/matrix/").concat(matrixId, "/start/").concat(start, "/end/").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessmentsForProgress = function getGuardianStudentAssessmentsForProgress(studentId, startDate, endDate) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSESSMENTS_PROGRESS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/assessments/start/").concat(startDate, "/end/").concat(endDate, "/sections")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAsseessments = function getStudentAsseessments(studentId, startDate, endDate) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSESSMENTS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/rawassessments/start/").concat(startDate, "/end/").concat(endDate)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getStudentAsseessmentsBySection = function getStudentAsseessmentsBySection(studentId, sectionId, startDate, endDate) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSESSMENTS_BY_SECTION,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/rawassessments/section/").concat(sectionId, "/start/").concat(startDate, "/end/").concat(endDate)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getGuardianStudentAssessmentStatisticsForProgress = function getGuardianStudentAssessmentStatisticsForProgress(studentId, sectionType, start, end) {
  return {
    type: GET_GUARDIAN_STUDENT_ASSESSMENTS_STATISTIC_PROGRESS,
    payload: new Promise(function (resolve) {
      api.get("Guardians/me/students/".concat(studentId, "/sectionType/").concat(sectionType, "/start/").concat(start, "/end/").concat(end, "/assessmentStatistics")).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getCourse = function getCourse(courseId) {
  return {
    type: GET_COURSES,
    payload: new Promise(function (resolve) {
      api.get("course/".concat(courseId)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};