import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { CLEAR_FORM, CLEAR_FORM_TEMPLATE, GET_FORM, GET_FORMS, GET_FORM_INSTANCE } from 'actions/templates';
var INITIAL_STATE = {
  templates: null,
  formTemplate: null,
  form: null,
  forms: null,
  instance: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case CLEAR_FORM_TEMPLATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        formTemplate: INITIAL_STATE.formTemplate
      });
    case GET_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        form: action.payload
      });
    case CLEAR_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        form: INITIAL_STATE.form,
        formTemplate: INITIAL_STATE.formTemplate
      });
    case GET_FORMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        forms: action.payload
      });
    case GET_FORM_INSTANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        instance: action.payload
      });
    default:
      return state;
  }
}