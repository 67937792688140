import { getActiveLanguage, setActiveLanguage } from '@haldor/ui';
import { putProfileInformation } from 'actions/profile';
export var languages = function languages() {
  var languages = [{
    svg_src: '/dist/svg/se.svg',
    label: 'Svenska',
    lcid: 'sv-SE'
  }, {
    svg_src: '/dist/svg/gb.svg',
    label: 'English',
    lcid: 'en-US'
  }, {
    svg_src: '/dist/svg/no.svg',
    label: 'Norsk',
    lcid: 'nb-NO'
  }, {
    svg_src: '/dist/svg/dk.svg',
    label: 'Dansk',
    lcid: 'da-DK'
  }, {
    svg_src: '/dist/svg/uk.svg',
    label: 'Український',
    lcid: 'uk'
  }];
  return languages;
};
export var getLanguage = function getLanguage() {
  var activeLanguage = languages().find(function (x) {
    return x.lcid.toLowerCase() == getActiveLanguage().toLowerCase();
  });
  return activeLanguage;
};
export var setLanguage = function setLanguage(lcid, profile) {
  setActiveLanguage(lcid);
  if (profile != null) {
    profile.lcid = lcid;
    putProfileInformation(profile);
  }
};