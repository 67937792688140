import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_PAGE_ACTIONS, SET_PAGE_TITLE, ON_ROUTE_CHANGE } from 'actions/header';
import { backButtonEnable } from 'helpers/teams';
var INITIAL_STATE = {
  actions: null,
  title: null,
  backButton: false,
  pathname: window.location.pathname
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_PAGE_ACTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        actions: action.payload
      });
    case SET_PAGE_TITLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        title: action.payload
      });
    case ON_ROUTE_CHANGE:
      // Dispatched from routes/index.js
      if (window.location.pathname == state.pathname) {
        return state;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        title: null,
        actions: null,
        pathname: window.location.pathname,
        backButton: backButtonEnable()
      });
    default:
      return state;
  }
}