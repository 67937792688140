import api from '../lib/api';
export var GET_BLOCK_TYPES = 'BLOCKS_GET_BLOCK_TYPES';
export var SAVE_BLOCKS_ON_REFERENCE = 'BLOCKS_SAVE_BLOCKS_ON_REFERENCE';
export var UPDATE_BLOCKS_ON_REFERENCE = 'BLOCKS_UPDATE_BLOCKS_ON_REFERENCE';
export var GET_BLOCKS_BY_REFERENCE = 'BLOCKS_GET_BLOCKS_BY_REFERENCE';
export var getBlockTypes = function getBlockTypes() {
  return {
    type: GET_BLOCK_TYPES,
    payload: new Promise(function (resolve, reject) {
      return api.get("blocks/types").then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var getBlocksByReference = function getBlocksByReference(referenceId, referenceType) {
  return {
    type: GET_BLOCKS_BY_REFERENCE,
    payload: new Promise(function (resolve, reject) {
      api.get("blocks?referenceId=".concat(referenceId, "&referenceType=").concat(referenceType)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};