import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { GET_CONVERSATIONS, GET_CONVERSATION, GET_CONVERSATIONS_REFERENCE, RESET_CONVERSATION, GET_CONVERSATION_MESSAGES, GET_USER_CONTACTS, GET_UNREAD_COUNT, SEARCH_CONVERSATIONS, CLEAR_SEARCH_CONVERSATIONS, FLAG_MESSAGE, STAGE_CONVERSATION, SUBMIT_CONVERSATION_MESSAGE, SUBMIT_CONVERSATION } from 'actions/conversation';
var INITIAL_STATE = {
  conversations: null,
  conversation: null,
  messages: [],
  contacts: null,
  staged: null,
  "new": null,
  unread: [],
  searchResults: null
};
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: action.payload
      });
    case GET_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversation: action.payload
      });
    case GET_CONVERSATIONS_REFERENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        referenceConversations: action.payload
      });
    case GET_CONVERSATION_MESSAGES:
      if (action.payload != null && action.payload.length > 0) {
        var _state$messages;
        return _objectSpread(_objectSpread({}, state), {}, {
          messages: (_state$messages = state.messages).unshift.apply(_state$messages, _toConsumableArray(action.payload))
        });
      }
      return state;
    case SUBMIT_CONVERSATION_MESSAGE:
      if (state.conversation != null) {
        var conversationMessage = _objectSpread({}, state.conversation);
        conversationMessage.messages.push(action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          conversation: conversationMessage
        });
      }
      return state;
    case GET_USER_CONTACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        contacts: action.payload
      });
    case GET_UNREAD_COUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        unread: action.payload
      });
    case SEARCH_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: action.payload
      });
    case CLEAR_SEARCH_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: INITIAL_STATE.searchResults
      });
    case STAGE_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        staged: action.payload
      });
    case SUBMIT_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        "new": action.payload
      });
    case RESET_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversation: INITIAL_STATE.conversation
      });
    case FLAG_MESSAGE:
      if (state.conversation != null) {
        if (state.conversation.messages != null && state.conversation.messages.length > 0) {
          var conversation = _objectSpread({}, state.conversation);
          var foundMessage = conversation.messages.findIndex(function (message) {
            return message.id == action.payload.messageId;
          });
          if (foundMessage > -1) {
            var newMessage = _objectSpread({}, conversation.messages[foundMessage]);
            newMessage.flagID = action.payload.flagID;
            newMessage.text = '';
            conversation.messages.splice(foundMessage, 1, newMessage);
            return _objectSpread(_objectSpread({}, state), {}, {
              conversation: conversation
            });
          }
        }
      }
      return state;
    default:
      return state;
  }
}