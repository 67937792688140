import api from '../lib/api';
export var GET_USER_ATTENDANCE = 'ATTENDANCE_GET_USER_ATTENDANCE';
export var CLEAR_USER_ATTENDANCE = 'ATTENDANCE_CLEAR_USER_ATTENDANCE';
export var getUserAttendance = function getUserAttendance(user, start, end) {
  return {
    type: GET_USER_ATTENDANCE,
    payload: new Promise(function (resolve) {
      api.get("students/".concat(user, "/attendance?startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      });
    })
  };
};
export var clearStudentAttendance = function clearStudentAttendance() {
  return {
    type: CLEAR_USER_ATTENDANCE,
    payload: true
  };
};