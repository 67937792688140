import api from '../lib/api';
export var GET_USER_ABSENCE = 'ABSENCE_GET_USER_ABSENCE';
export var GET_USER_AGGREGATED_ATTENDANCE = 'ABSENCE_GET_USER_AGGREGATED_ATTENDANCE';
export var SUBMIT_USER_ABSENCE = 'ABSENCE_SUBMIT_USER_ABSENCE';
export var UPDATE_USER_ABSENCE = 'ABSENCE_UPDATE_USER_ABSENCE';
export var REMOVE_USER_ABSENCE = 'ABSENCE_REMOVE_USER_ABSENCE';
export var CLEAR_STUDENT_ABSENCE = 'CLEAR_STUDENT_ABSENCE';
export var getUserAbsence = function getUserAbsence(user, start, end) {
  return {
    type: GET_USER_ABSENCE,
    payload: new Promise(function (resolve) {
      api.get("students/".concat(user, "/absence/?startDate=").concat(start, "&endDate=").concat(end)).then(function (response) {
        resolve(response.data);
      })["catch"](function (data) {
        reject(null);
      });
    })
  };
};
export var submitAbsence = function submitAbsence(values) {
  return {
    type: SUBMIT_USER_ABSENCE,
    payload: new Promise(function (resolve, reject) {
      var data = JSON.stringify(values);
      api.post("absence", data).then(function (response) {
        resolve(response.data);
      })["catch"](function (data) {
        reject(null);
      });
    })
  };
};
export var updateAbsence = function updateAbsence(values) {
  return {
    type: UPDATE_USER_ABSENCE,
    payload: new Promise(function (resolve, reject) {
      var data = JSON.stringify(values);
      api.put("absence", data).then(function (response) {
        resolve(response.data);
      })["catch"](function (data) {
        reject(null);
      });
      ;
    })
  };
};
export var deleteAbsence = function deleteAbsence(id) {
  return {
    type: REMOVE_USER_ABSENCE,
    payload: new Promise(function (resolve) {
      api["delete"]("absence/".concat(id)).then(function (data) {
        resolve(id);
      })["catch"](function (data) {
        reject(null);
      });
      ;
    })
  };
};
export var clearStudentAbsences = function clearStudentAbsences() {
  return {
    type: CLEAR_STUDENT_ABSENCE,
    payload: []
  };
};